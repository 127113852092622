<template>
  <div>
    <div class="mb20">
      共
      <span class="highlight">{{ list.length }}</span>
      位技术负责人
    </div>

    <el-form ref="form" :model="form" label-width="70px" size="small">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="人员姓名">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入人员完整姓名"
              clearable
              @keyup.enter.native="query"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-button size="small" @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button
            size="small"
            type="primary"
            @click="query"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      ref="personnel"
    >
      <el-table-column type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="name"
        min-width="100"
        :show-overflow-tooltip="true"
        label="姓名"
      >
        <template slot-scope="scope">
          <div
            class="link"
            @click="
              toDetail(
                scope.row.companyId,
                scope.row.name,
                scope.row.recentResult
              )
            "
          >
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="certificateNo"
        min-width="100"
        :show-overflow-tooltip="true"
        label="注册号(执业印章号)"
      ></el-table-column>

      <el-table-column
        sortable
        prop="recentResult"
        min-width="100"
        :show-overflow-tooltip="true"
        label="最近中标时间"
      ></el-table-column>

      <el-table-column
        sortable
        prop="resultCount"
        min-width="100"
        :show-overflow-tooltip="true"
        label="业绩(个)"
      ></el-table-column>

      <el-table-column
        sortable
        prop="integrityCount"
        min-width="100"
        :show-overflow-tooltip="true"
        label="诚信(个)"
      ></el-table-column>

      <el-table-column
        sortable
        prop="honorCount"
        min-width="100"
        :show-overflow-tooltip="true"
        label="荣誉(个)"
      ></el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[20, 50, 100, 150, 200]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 企业详情-技术负责人 */
// 引入ajax函数
import { searchTechnician } from "@/api/itemizedQuery";

export default {
  props: ["companyId"],

  // 数据
  data() {
    return {
      form: {
        name: "", // 姓名
        pageNum: 1,
        pageSize: 20,
      },

      list: [],
      total: 0,
      loading: false,
    };
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;

      const params = {
        ...this.form,
        companyId: this.companyId,
      };
      let res = await searchTechnician(params);
      res = res.data;
      res.records.forEach((v) => {
        v.resultCount = v.resultCount || "--";
        v.integrityCount = v.integrityCount || "--";
        v.honorCount = v.honorCount || "--";
        if (v.recentResult.includes(" ")) {
          v.recentResult = v.recentResult.split(" ")[0];
        }
      });
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id, name, time) {
      this.$router.push({
        name: "personDetails",
        params: { id },
        query: { name, time },
      });
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },

    // 重置
    reset() {
      this.form = {
        name: "", // 姓名
        pageNum: 1,
        pageSize: 20,
      };
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },
    // 初始化
    __init__() {
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped></style>
