<template>
  <div class="honor">
    <div class="mb20">
      共
      <span class="highlight">{{ list.length }}</span>
      条荣誉信息
    </div>

    <el-form ref="form" :model="form" label-width="70px" size="small">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="荣誉名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入荣誉名称关键词"
              clearable
              @keyup.enter.native="query"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-button size="small" @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button
            size="small"
            type="primary"
            @click="query"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      ref="personnel"
    >
      <el-table-column type="index" width="50"> </el-table-column>

      <el-table-column
        sortable
        prop="name"
        min-width="100"
        :show-overflow-tooltip="true"
        label="荣誉名称"
      >
        <template slot-scope="scope">
          <div class="link" @click="toDetail(scope.row.id)">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        sortable
        prop="content"
        min-width="100"
        :show-overflow-tooltip="true"
        label="荣誉详情"
      ></el-table-column>

      <el-table-column
        sortable
        prop="company"
        min-width="100"
        :show-overflow-tooltip="true"
        label="所属公司"
      ></el-table-column>

      <el-table-column
        sortable
        prop="announcedUnits"
        min-width="100"
        :show-overflow-tooltip="true"
        label="信息来源"
      ></el-table-column>

      <el-table-column
        sortable
        prop="manager"
        min-width="100"
        :show-overflow-tooltip="true"
        label="项目经理"
      ></el-table-column>

      <el-table-column
        sortable
        prop="time"
        min-width="100"
        :show-overflow-tooltip="true"
        label="获奖时间"
      ></el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[20, 50, 100, 150, 200]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
/* 企业详情-荣誉信息 */
// 引入ajax函数
import { searchHonor } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";

export default {
  props: ["companyId"],

  // 数据
  data() {
    return {
      form: {
        name: "", // 荣誉名称
        companyId: "", // 公司ID
        pageNum: 1,
        pageSize: 20,
      },

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal) {
          this.__init__();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;

      const params = {
        ...this.form,
        companyId: this.companyId,
      };

      const { data } = await searchHonor(params);
      data.records.forEach((v) => {
        if (!v.manager || v.manager == "-1") {
          v.manager = "--";
        }
      });
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({ name: "honorDetails", params: { id } });
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        name: "", // 诚信姓名
        companyId: "", // 公司ID
        pageNum: 1,
        pageSize: 20,
      };
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.getData();
    },
    // 初始化
    __init__() {
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped></style>
