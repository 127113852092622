<template>
  <div class="company_details container" v-loading="loading">
    <companyInfo :details="details" />

    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane label="资质等级" name="资质等级" lazy>
        <certification :list="details.certDetailDtoList" />
      </el-tab-pane>

      <el-tab-pane label="企业业绩" name="企业业绩" lazy>
        <performance :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="注册人员" name="注册人员" lazy>
        <personnel :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="项目经理" name="项目经理" lazy>
        <manager :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="技术负责人" name="技术负责人" lazy>
        <technicalDirector :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="诚信信息" name="诚信信息" lazy>
        <integrity :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="荣誉信息" name="荣誉信息" lazy>
        <honor :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="评价信息" name="评价信息" lazy>
        <evaluate :companyId="companyId" />
      </el-tab-pane>

      <el-tab-pane label="工商信息" name="工商信息" lazy>
        <business :details="details" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/* 公司详情 */
import { searchCompanyById } from "@/api/companyDetails.js";
import companyInfo from "./components/companyInfo.vue";
import certification from "./components/certification.vue";
import performance from "./components/performance.vue";
import personnel from "./components/personnel.vue";
import manager from "./components/manager.vue";
import technicalDirector from "./components/technicalDirector.vue";
import integrity from "./components/integrity.vue";
import honor from "./components/honor.vue";
import evaluate from "./components/evaluate.vue";
import business from "./components/business.vue";

export default {
  components: {
    companyInfo,
    certification,
    performance,
    personnel,
    manager,
    technicalDirector,
    integrity,
    honor,
    evaluate,
    business,
  },

  data() {
    return {
      activeName: "资质等级",
      details: { certDetailDtoList: [] },
      companyId: "",
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取公司详情数据
    async getData() {
      this.loading = true;
      let res = await searchCompanyById({ companyId: this.companyId });
      res = res.data;
      if (!res.nameUsed || res.nameUsed == "-" || res.nameUsed == "-1") {
        res.nameUsed = "暂无曾用名";
      }
      if (
        res.phone &&
        res.phone != "-" &&
        res.otherPhone &&
        res.otherPhone != "-"
      ) {
        res["contactPhone"] = res.phone + ";" + res.otherPhone;
      } else if (res.phone && res.phone != "-") {
        res["contactPhone"] = res.phone;
      } else if (res.otherPhone && res.otherPhone != "-") {
        res["contactPhone"] = res.otherPhone;
      } else {
        res["contactPhone"] = "暂无联系方式";
      }
      if (res.contactPhone != "暂无联系方式") {
        res["contactPhoneList"] = res["contactPhone"].split(";");
      }
      this.details = res;
      this.loading = false;
    },

    /* 操作 */
    // tab的点击事件
    handleTabClick(tab) {
      this.activeName = tab.name;
      this.$router.push({
        name: "companyDetails",
        params: { name: tab.name },
        query: { id: this.companyId },
      });
    },

    /* 初始化 */
    __init__() {
      this.companyId = this.$route.query.id || this.companyId;
      this.getData();
    },
  },

  watch: {
    // 监听,当路由发生变化的时候执行
    $route: {
      // $route可以用引号，也可以不用引号
      handler(to, from) {
        let name = this.$route.params.name;
        this.activeName = name;
      },
      deep: true, // 深度观察监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.company_details {
  /deep/.el-tabs__header,
  /deep/.el-tabs__content {
    background-color: #fff;
    padding: 20px;
  }

  /deep/.el-tabs__header {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    z-index: 2000;
    top: 70px;
  }

  /deep/.el-collapse-item__content {
    padding-bottom: 0px;
  }
  /deep/.el-descriptions__body {
    padding: 20px;
    background-color: #f5faff;
  }
}
</style>
